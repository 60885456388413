@charset "UTF-8";
/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
https://larsenwork.com/easing-gradients/
https://easings.net/pt-br
*/
@media (min-width: 992px) {
  .swiper-slide .container, .swiper-slide .container-lg, .swiper-slide .container-md, .swiper-slide .container-sm, .swiper-slide .container-xl, #compromisso .container, #compromisso .container-lg, #compromisso .container-md, #compromisso .container-sm, #compromisso .container-xl {
    max-width: 1200px;
  }

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 960px;
  }
}
@font-face {
  font-family: "anivers";
  src: url("../fonts/anivers_regular-webfont.woff2") format("woff2"), url("../fonts/anivers_regular-webfont.woff") format("woff"), url("../fonts/anivers_regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "anivers";
  src: url("../fonts/anivers-bold-webfont.woff2") format("woff2"), url("../fonts/anivers-bold-webfont.woff") format("woff"), url("../fonts/anivers-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
https://larsenwork.com/easing-gradients/
https://easings.net/pt-br
*/
@media (min-width: 992px) {
  .swiper-slide .container, .swiper-slide .container-lg, .swiper-slide .container-md, .swiper-slide .container-sm, .swiper-slide .container-xl, #compromisso .container, #compromisso .container-lg, #compromisso .container-md, #compromisso .container-sm, #compromisso .container-xl {
    max-width: 1200px;
  }

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 960px;
  }
}
html, body {
  font-family: "anivers";
  font-size: 18px;
}

body {
  overflow-x: hidden;
}
body:not(.touch) {
  background-image: url(../images/bg.jpg);
  background-position: top center;
  background-attachment: fixed;
}
body.touch::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url(../images/bg.jpg) no-repeat top center;
  background-size: cover;
}

.center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

button, .button {
  background: none;
  border: 0;
  outline: none !important;
  position: relative;
  padding: 5px 20px;
  display: inline-block;
  color: #FFF;
  transition: color 0.5s;
}
button:not(.nt-link):not(.mfp-close):not(.mfp-arrow):not(.navbar-opener):not(.navbar-closer):not(.serv_prev):not(.serv_next):not(.submit):not(.sbtprev):not(.sbtnext)::before, .button:not(.nt-link):not(.mfp-close):not(.mfp-arrow):not(.navbar-opener):not(.navbar-closer):not(.serv_prev):not(.serv_next):not(.submit):not(.sbtprev):not(.sbtnext)::before {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  transform: skew(-45deg);
  background-color: #a9001c;
  top: 0;
  left: 0;
  transition: background-color 0.5s;
}
button:not(.nt-link):not(.mfp-close):not(.mfp-arrow):not(.navbar-opener):not(.navbar-closer):not(.serv_prev):not(.serv_next):not(.submit):not(.sbtprev):not(.sbtnext):hover, .button:not(.nt-link):not(.mfp-close):not(.mfp-arrow):not(.navbar-opener):not(.navbar-closer):not(.serv_prev):not(.serv_next):not(.submit):not(.sbtprev):not(.sbtnext):hover {
  color: #FFF;
  text-decoration: none;
}
button:not(.nt-link):not(.mfp-close):not(.mfp-arrow):not(.navbar-opener):not(.navbar-closer):not(.serv_prev):not(.serv_next):not(.submit):not(.sbtprev):not(.sbtnext):hover::before, .button:not(.nt-link):not(.mfp-close):not(.mfp-arrow):not(.navbar-opener):not(.navbar-closer):not(.serv_prev):not(.serv_next):not(.submit):not(.sbtprev):not(.sbtnext):hover::before {
  background-color: #7c7c7c;
}

strong, h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h2 {
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #a9001c;
}
h2.titulo {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.12rem;
  text-transform: uppercase;
  color: #101952;
}
h3.subtitulo {
  color: #7c7c7c;
  text-transform: none;
  font-size: 1.5rem;
}

h4 {
  font-size: 1.08rem;
  text-transform: uppercase;
  color: #101952;
}

form label, form input, form textarea, form select, form button {
  border: 0;
  outline: none;
}
form label {
  color: #7c7c7c;
  line-height: 1.5rem;
}
form textarea {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 5px;
  line-height: 1.5rem !important;
}
form button {
  background: none;
  border: 0;
  outline: 0;
  position: relative;
  padding: 5px 20px;
  display: inline-block;
  color: #FFF;
  transition: color 0.5s;
}
form button:hover {
  color: #FFF;
  text-decoration: none;
}
form button:hover::before {
  background-color: #a9001c;
}
form input, form select, form button {
  line-height: 2.5rem;
}
form label, form input, form textarea, form select {
  width: 100%;
}
form input, form textarea, form select {
  padding-left: 8px;
  padding-right: 8px;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
header .container {
  z-index: 1;
  position: relative;
}
header .container .row {
  padding-top: 10px;
}
header .container .row .logo {
  width: 229.6px;
  height: 87.9px;
  transform: scale(0.85);
  margin-top: -8px;
  margin-left: -15px;
}
header .container .row .header-top {
  height: 50px;
  line-height: 50px;
  padding-right: 1rem;
}
header .container .row .header-top * {
  vertical-align: middle;
}
header .container .row .header-top span {
  padding-right: 10px;
}
header .container .row .header-top span a {
  color: unset !important;
}
header .container .row .header-top i {
  color: #a9001c;
  font-size: 1.4rem;
}
header .container .row nav {
  padding: 0;
  line-height: 1.777778rem;
}
header .container .row nav button {
  display: none;
}
header .container .row nav ul {
  flex-direction: row;
}
header .container .row nav ul li {
  display: inline-block;
}
header .container .row nav .nav-link {
  padding: 0 1rem 0 1.2rem;
  color: rgba(0, 0, 0, 0.5);
}
header .container .row nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
  background-image: url(../images/seta_menu.png);
  background-repeat: no-repeat;
  background-position: left center;
}
header::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
}
header::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(255, 255, 255, 0.75);
}
@media (max-width: 767px) {
  header .container .row .header-top span {
    display: none;
  }
  header .container .row nav button {
    display: inline-block;
    margin-top: 3px;
    border: 0;
    background-color: transparent;
  }
  header .container .row nav #closebt {
    color: #FFF;
    font-size: 1.5rem;
    position: absolute;
    top: 5px;
    right: 0;
  }
  header .container .row nav #menubt {
    color: #000;
    padding: 0 20px;
  }
  header .container .row nav #navbarSupportedContent {
    background: rgba(16, 25, 82, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: opacity 0.5s;
  }
  header .container .row nav #navbarSupportedContent.collapsed {
    display: none;
  }
  header .container .row nav #navbarSupportedContent.open {
    display: block;
    opacity: 0;
  }
  header .container .row nav #navbarSupportedContent.expanded {
    opacity: 1 !important;
  }
  header .container .row nav #navbarSupportedContent.closing {
    opacity: 0 !important;
  }
  header .container .row nav #navbarSupportedContent ul.navbar-nav {
    height: 100%;
    justify-content: center !important;
    flex-direction: column;
    align-items: center;
  }
  header .container .row nav #navbarSupportedContent .nav-item {
    margin-bottom: 1.3rem;
  }
  header .container .row nav #navbarSupportedContent .nav-link {
    font-size: 1.8rem;
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
  }
  header .container .row nav #navbarSupportedContent .nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}
@media (max-width: 390px) {
  header {
    height: 76px;
  }
  header .container .row {
    padding-top: 0;
  }
  header .container .row .logo {
    transform: scale(0.65);
    margin-top: -5px;
    margin-left: -40px;
  }
  header .container .row nav {
    margin-top: -3px;
  }
  header .container .row nav #menubt {
    margin-top: 0;
  }
  header::before {
    height: 50px;
  }
  header::after {
    height: calc(100% - 50px);
  }
}

footer {
  background-color: #101952;
  color: #FFF;
}
footer nav ul {
  list-style: none;
}
footer nav ul li {
  padding: 0;
}
@media (max-width: 767px) {
  footer nav ul li a {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

#banner {
  width: 100%;
  height: 100vh;
  max-height: 800px;
  position: relative;
  overflow: hidden;
}
#banner .swiper-container {
  height: 100% !important;
}
#banner .swiper-container .swiper-pagination {
  z-index: 100;
  bottom: 20px !important;
  top: unset !important;
}
#banner .swiper-container .swiper-pagination.bullets .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: #101952;
  border: 2px solid #FFF;
}
#banner .swiper-container .swiper-pagination.bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #FFF;
  border: 2px solid #101952;
}
#banner .swiper-container .swiper-pagination.fraction {
  color: #000;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.85);
}
#banner .swiper-container .swiper-pagination.progressbar {
  bottom: 302px !important;
}
#banner .swiper-container .swiper-button-prev, #banner .swiper-container .swiper-button-next {
  z-index: 200;
  color: #FFF;
  text-shadow: 0 0 20px rgba(14, 11, 11, 0.85);
  transform: translateY(27px);
}
#banner .swiper-container .swiper-wrapper {
  align-items: center;
  height: 100%;
}
#banner .swiper-container .swiper-wrapper .swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #01032F;
}
#banner .swiper-container .swiper-wrapper .swiper-slide::before, #banner .swiper-container .swiper-wrapper .swiper-slide::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  z-index: 2;
  opacity: 0;
  transition: left 1s ease 0.5s, opacity 1s ease 0.5s;
}
#banner .swiper-container .swiper-wrapper .swiper-slide::before {
  width: 1266px;
  left: 30%;
  transform: translateX(calc(-100% + -450px));
  background-position: right center;
  background-image: url(../images/banner_seta_esq.png);
}
#banner .swiper-container .swiper-wrapper .swiper-slide::after {
  width: 1800px;
  left: 30%;
  transform: translateX(-450px);
  background-position: left center;
  background-image: url(../images/banner_seta_dir.png);
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont {
  position: relative;
  z-index: 10;
  opacity: 0;
  transition: opacity 1s ease 1s;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row {
  padding-top: 91px;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * {
  width: 350px;
  text-shadow: 0 0 20px black;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a {
  text-decoration: none;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span {
  color: #FFF;
  display: block;
  border-bottom: 1px solid #7171AB;
  font-size: 1.8rem;
  padding: 10px 0;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(1), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(1) {
  text-transform: uppercase;
  padding-top: 0;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(3), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(3) {
  font-size: 1.5rem;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(3), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(3) {
  color: #7171AB;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span span {
  display: block;
  font-size: inherit;
  line-height: inherit;
  border-bottom: 0;
  padding: 0;
  text-transform: initial !important;
  color: inherit;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder::before {
  width: 20%;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 20%;
  background: linear-gradient(to left, #101952 0%, rgba(16, 25, 82, 0.987) 8.1%, rgba(16, 25, 82, 0.951) 15.5%, rgba(16, 25, 82, 0.896) 22.5%, rgba(16, 25, 82, 0.825) 29%, rgba(16, 25, 82, 0.741) 35.3%, rgba(16, 25, 82, 0.648) 41.2%, rgba(16, 25, 82, 0.55) 47.1%, rgba(16, 25, 82, 0.45) 52.9%, rgba(16, 25, 82, 0.352) 58.8%, rgba(16, 25, 82, 0.259) 64.7%, rgba(16, 25, 82, 0.175) 71%, rgba(16, 25, 82, 0.104) 77.5%, rgba(16, 25, 82, 0.049) 84.5%, rgba(16, 25, 82, 0.013) 91.9%, rgba(16, 25, 82, 0) 100%);
  opacity: 0;
  transition: opacity 1s;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder::after {
  width: 20%;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: #101952;
  opacity: 0;
  transition: opacity 1s;
}
#banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder .slide-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 40%;
  z-index: 1;
  opacity: 0;
  transform: translateY(-50%) translateX(calc(-50% + -180px));
  background-position: center;
  background-repeat: no-repeat;
  transition: left 1s ease 0.5s, opacity 1s ease 0.5s;
  -webkit-mask-image: linear-gradient(to right, black 90%, rgba(0, 0, 0, 0));
          mask-image: linear-gradient(to right, black 90%, rgba(0, 0, 0, 0));
}
#banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::before {
  left: 50%;
  opacity: 1;
}
#banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::after {
  left: 50%;
  opacity: 1;
}
#banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .slide-cont {
  opacity: 1;
}
#banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .slide-bgholder::before {
  opacity: 1;
}
#banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .slide-bgholder::after {
  opacity: 1;
}
#banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .slide-bgholder .slide-bg {
  left: 50%;
  opacity: 1;
}
@media (max-height: 1040px) {
  #banner {
    max-height: 720px;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::before {
    transform: translateX(calc(-100% + -450px));
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::after {
    transform: translateX(-495px);
  }
}
@media (max-height: 1040px) and (max-width: 991px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * {
    width: 300px;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder .slide-bg {
    transform: translateY(-50%) translateX(calc(-50% + -240px));
  }
}
@media (max-height: 920px) {
  #banner {
    max-height: 570px;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span {
    font-size: 1.5rem;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(3), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(3) {
    font-size: 1.2rem;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::before {
    transform: translateX(calc(-100% + -450px));
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::after {
    transform: translateX(-575px);
  }
}
@media (max-height: 820px) {
  #banner {
    max-height: 470px;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span {
    font-size: 1.3rem;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(3), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(3) {
    font-size: 1rem;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder .slide-bg {
    transform: translateY(-35%) translateX(calc(-50% + -240px));
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::before {
    transform: translateX(calc(-100% + -450px));
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::after {
    transform: translateX(-630px);
  }
}
@media (max-height: 650px) {
  #banner {
    max-height: 100vh;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder .slide-bg {
    transform: translateY(-40%) translateX(calc(-50% + -240px));
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::before {
    transform: translateX(calc(-100% + -450px));
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active::after {
    transform: translateX(-450px);
  }
}
@media (max-height: 506px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span {
    line-height: 1.3rem;
    padding: 6px 0;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(3), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(3) {
    line-height: 1.2rem;
  }
}
@media (max-height: 506px) and (min-width: 306px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span span {
    display: inline-block;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span span:nth-child(odd)::before, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span span:nth-child(odd)::before {
    content: "";
    display: block;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span span:nth-child(even)::before, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span span:nth-child(even)::before {
    content: " - ";
  }
}
@media (max-height: 506px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="2"] .slide-cont .row > * > a > span:nth-child(2), #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="2"] .slide-cont .row > * > span:nth-child(2) {
    padding-left: calc(50% - 92px);
    padding-right: calc(50% - 92px);
    box-sizing: border-box;
  }
}
@media (max-width: 305px) and (max-height: 506px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span span {
    display: inline-block;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span span:nth-child(even)::before, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span span:nth-child(even)::before {
    content: " - ";
  }
}
@media (max-width: 575px) {
  #banner {
    max-height: 100vh;
  }
}
@media (max-width: 767px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row {
    padding-top: 97px;
  }
}
@media (max-width: 991px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide::before, #banner .swiper-container .swiper-wrapper .swiper-slide::after {
    display: none;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * {
    width: 100%;
    padding: 0 60px !important;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span, #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span {
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
    text-align: center;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > a > span:nth-child(3), #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row > * > span:nth-child(3) {
    color: #9797c9;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder::before {
    display: none;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder::after {
    width: 100%;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-bgholder .slide-bg {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-mask-image: unset;
            mask-image: unset;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .slide-bgholder::after {
    opacity: 1;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="0"] .slide-bgholder::after {
    background: linear-gradient(135deg, rgba(12, 18, 61, 0.95) 20%, rgba(12, 18, 61, 0.7) 70%, rgba(12, 18, 61, 0.4) 100%);
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="0"] .slide-bgholder .slide-bg {
    background-position: center bottom !important;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="1"] .slide-bgholder::after {
    background: linear-gradient(90deg, rgba(12, 18, 61, 0.4) 0%, rgba(12, 18, 61, 0.7) 10%, rgba(12, 18, 61, 0.9) 35%, rgba(12, 18, 61, 0.9) 65%, rgba(12, 18, 61, 0.7) 90%, rgba(12, 18, 61, 0.4) 100%);
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="1"] .slide-bgholder .slide-bg {
    background-position: center bottom !important;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="2"] .slide-bgholder::after {
    background: linear-gradient(315deg, rgba(12, 18, 61, 0.4) 0%, rgba(12, 18, 61, 0.6) 10%, rgba(12, 18, 61, 0.9) 45%, rgba(12, 18, 61, 0.9) 55%, rgba(12, 18, 61, 0.6) 90%, rgba(12, 18, 61, 0.4) 100%);
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="2"] .slide-bgholder .slide-bg {
    background-position: left bottom !important;
  }
}
@media (max-width: 390px) {
  #banner .swiper-container .swiper-wrapper .swiper-slide .slide-cont .row {
    padding-top: 76px;
  }
  #banner .swiper-container .swiper-wrapper .swiper-slide[data-swiper-slide-index="2"] .slide-bgholder .slide-bg {
    background-position: -212px bottom !important;
  }
}

#compromisso {
  margin-bottom: 90px;
}
#compromisso .container {
  position: relative;
  -webkit-mask-image: linear-gradient(to bottom, black 80%, rgba(0, 0, 0, 0));
          mask-image: linear-gradient(to bottom, black 80%, rgba(0, 0, 0, 0));
}
#compromisso .container::before, #compromisso .container::after {
  content: "";
  display: block;
  position: absolute;
  height: 300%;
  width: 5px;
  top: -10px;
  background: #7c7c7c;
  transform-origin: top center;
  box-shadow: 5px 0 0 #c9c9c9;
}
#compromisso .container::before {
  left: 348px;
  transform: rotate(45deg) translate(0, 0);
}
#compromisso .container::after {
  right: 0;
  transform: rotate(45deg) translate(0, 0);
}
#compromisso .container .row .col-12 {
  padding-bottom: 90px;
  padding-top: 30px;
  padding-left: 360px;
  padding-right: 200px;
}
#compromisso .container .row .col-12 .mask-left {
  shape-outside: polygon(0px 0px, 352px 0px, 0px 370px);
  width: 30%;
  height: 100%;
  float: left;
}
#compromisso .container .row .col-12 .mask-right {
  shape-outside: polygon(83% 0px, 100% 0, 100% 370px, 0 370px);
  width: 38%;
  height: 100%;
  float: right;
}
#compromisso .container .row .col-12 .button.center {
  left: 40%;
  margin-top: 15px;
}
@media (max-width: 1199px) {
  #compromisso .container::before, #compromisso .container::after {
    display: none;
  }
  #compromisso .container .row .col-12 {
    padding-left: 90px;
    padding-right: 90px;
  }
  #compromisso .container .row .col-12 .mask-left, #compromisso .container .row .col-12 .mask-right {
    display: none;
  }
  #compromisso .container .row .col-12 .button.center {
    left: 50%;
  }
}
@media (max-width: 991px) {
  #compromisso .container .row .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#quem_somos {
  background-image: url(../images/quem_somos.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 650px;
  max-height: 800px;
  overflow: hidden;
  position: relative;
}
#quem_somos::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 423px;
  height: 100%;
  min-height: 800px;
  left: calc(50% - 480px);
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
  background-image: url(../images/quem_somos_bg_esq.png);
  background-position: left center;
  background-repeat: no-repeat;
}
#quem_somos::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 676px;
  height: 100%;
  min-height: 800px;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../images/quem_somos_bg_dir.png);
  background-position: left center;
  background-repeat: no-repeat;
}
#quem_somos .bgwhite {
  position: absolute;
  z-index: 1;
  width: 480px;
  height: 100%;
  min-height: 800px;
  left: calc(50% - 480px);
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
#quem_somos .container {
  padding-top: 91px;
}
#quem_somos .container, #quem_somos .row {
  position: relative;
  z-index: 5;
}
#quem_somos .row .col-12 {
  padding-right: 280px;
}
#quem_somos .row .col-12 .qs_pilares {
  display: flex;
  padding-top: 20px;
  width: calc(100% + 140px);
}
#quem_somos .row .col-12 .qs_pilares .qs_col {
  padding-left: 15px;
  padding-right: 30px;
  border-left: 3px solid #101952;
}
@media (max-width: 1199px) {
  #quem_somos .row .col-12 {
    padding-left: 120px;
  }
}
@media (max-width: 991px) {
  #quem_somos .row .col-12 {
    padding-right: 140px;
  }
}
@media (max-width: 767px) {
  #quem_somos .row .col-12 {
    padding-right: 15px;
  }
  #quem_somos .row .col-12 .qs_pilares {
    width: 100%;
    flex-direction: column;
  }
  #quem_somos .row .col-12 .qs_pilares .qs_col {
    padding-left: 0;
    padding-right: 0;
    border-left: 0;
    border-bottom: 3px solid #101952;
    margin-bottom: 18px;
  }
  #quem_somos .row .col-12 .qs_pilares .qs_col:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}
@media (min-width: 1806px) {
  #quem_somos::before {
    left: 0;
    transform: translateX(0px) translateY(-50%);
    background-image: url(../images/quem_somos_bg_esq.png);
    background-position: left center;
    background-repeat: no-repeat;
  }
  #quem_somos .bgwhite {
    left: 423px;
  }
}
@media (max-width: 1199px) {
  #quem_somos::before {
    left: calc(50% - 360px);
  }
  #quem_somos .bgwhite {
    width: 360px;
    left: calc(50% - 360px);
  }
}
@media (max-width: 991px) {
  #quem_somos {
    max-height: unset;
    height: unset;
  }
  #quem_somos::before {
    left: calc(50% - 260px);
  }
  #quem_somos .bgwhite {
    width: 260px;
    left: calc(50% - 260px);
  }
}
@media (max-width: 767px) {
  #quem_somos::before {
    display: none;
  }
  #quem_somos::after {
    display: none;
  }
  #quem_somos .bgwhite {
    width: 100%;
    left: 0;
    top: 0;
  }
  #quem_somos .row .col-12 {
    padding-left: 15px;
  }
}

body.touch #servicos {
  position: relative;
}
body.touch #servicos::before {
  top: 298px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  display: block;
  position: absolute;
  content: "←  Arraste   →";
  color: rgba(0, 0, 0, 0.8);
}
body.touch #servicos .servicos-lista {
  overflow: scroll;
}
body.touch #servicos .servicos-lista > .container {
  width: 100%;
}
body.touch #servicos .servicos-lista > .container > .row {
  width: 100%;
}
body.touch #servicos .servicos-lista > .container > .row > .col-12 {
  width: 100%;
}
body.touch #servicos .servicos-lista > .container > .row > .col-12 .serv_prev {
  display: none !important;
}
body.touch #servicos .servicos-lista > .container > .row > .col-12 .serv_next {
  display: none !important;
}
@media (max-width: 767px) {
  body.touch #servicos::before {
    top: 284px;
  }
}
@media (max-width: 460px) {
  body.touch #servicos::before {
    top: 218px;
  }
}

#servicos {
  padding-top: 90px;
  margin-bottom: 40px;
}
#servicos .servicos-lista {
  background-color: rgba(16, 25, 82, 0.95);
  height: 200px;
  overflow: hidden;
  /* @include respond-below(sm) {
      background-color: transparent; height: 70px;

      .col-12{
          height: 70px;

          .serv_prev, .serv_next{
              color: $azul;

              &::after{
                  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
              }
          }

          .nt-tabs{
              height: 70px;

              .servico{
                  width: auto; height: 70px;

                  &::before{display: none; clip-path: unset; background-image: unset; width: auto; height: auto;}
                  .label{width: auto; height: auto; left: unset; top: 50%; transform: translateY(-50%); position: relative; clip-path: unset;

                      span{position: static; left: unset; width: auto; background-color: transparent; color: rgba(0,0,0,0.7); transition: color .5s; padding: 0 20px;}
                  }
                  button{transform: unset;}

                  &:hover{
                      .label{
                          span{color: $azul;}
                      }

                  }
              }
          }
      }
  }

  @media (max-width: 620px) {
      .col-12{
          .serv_prev{
              left: 10px;
          }
          .serv_next{
              right: 10px;
          }
          .nt-tabs{
              padding: 0 50px; box-sizing: unset;
          }
      }
  } */
}
#servicos .servicos-lista .col-12 {
  position: relative;
  height: 200px;
}
#servicos .servicos-lista .col-12 .serv_prev, #servicos .servicos-lista .col-12 .serv_next {
  position: absolute;
  z-index: 100;
  top: 50%;
  width: 27px;
  height: 44px;
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  outline: none;
  color: #FFF;
}
#servicos .servicos-lista .col-12 .serv_prev::after, #servicos .servicos-lista .col-12 .serv_next::after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.85);
}
#servicos .servicos-lista .col-12 .serv_prev {
  left: -27px;
}
#servicos .servicos-lista .col-12 .serv_prev::after {
  content: "prev";
}
#servicos .servicos-lista .col-12 .serv_next {
  right: -27px;
}
#servicos .servicos-lista .col-12 .serv_next::after {
  content: "next";
}
@media (min-width: 960px) {
  #servicos .servicos-lista .col-12 .serv_prev {
    left: 27px;
  }
  #servicos .servicos-lista .col-12 .serv_next {
    right: 27px;
  }
}
#servicos .servicos-lista .col-12 .nt-tabs {
  gap: 0;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition: transform 0.5s;
}
#servicos .servicos-lista .col-12 .nt-tabs .servico {
  width: 300px;
  height: 200px;
  position: relative;
}
#servicos .servicos-lista .col-12 .nt-tabs .servico::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  width: 200%;
  height: 100%;
  left: -104px;
  -webkit-clip-path: polygon(34% 0%, 84% 0%, 51% 100%, 1% 100%);
          clip-path: polygon(34% 0%, 84% 0%, 51% 100%, 1% 100%);
  background-size: cover;
}
#servicos .servicos-lista .col-12 .nt-tabs .servico .label {
  width: 200%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: -104px;
  -webkit-clip-path: polygon(34% 0%, 84% 0%, 51% 100%, 1% 100%);
          clip-path: polygon(34% 0%, 84% 0%, 51% 100%, 1% 100%);
}
#servicos .servicos-lista .col-12 .nt-tabs .servico .label span {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: -20px;
  width: 67%;
  box-sizing: border-box;
  text-align: center;
  background-color: rgba(16, 25, 82, 0.7);
  color: #FFF;
}
#servicos .servicos-lista .col-12 .nt-tabs .servico button {
  position: absolute;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  transform: skew(-45deg);
  background-color: transparent;
  border: 0;
  outline: none;
}
#servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(1)::before {
  background-image: url(../images/servicos/serv_tapetes_menu.jpg);
}
#servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(2)::before {
  background-image: url(../images/servicos/serv_carpete_menu.jpg);
}
#servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(3)::before {
  background-image: url(../images/servicos/serv_ec_menu.jpg);
}
#servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(4)::before {
  background-image: url(../images/servicos/serv_dp_menu.jpg);
}
#servicos .servicos-lista .col-12 .nt-tabs.centered {
  left: 50% !important;
  transform: translateX(-50%) !important;
}
@media (max-width: 767px) {
  #servicos .servicos-lista {
    height: 180px;
  }
  #servicos .servicos-lista .col-12 {
    height: 180px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs {
    height: 180px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico {
    height: 180px;
    width: 160px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico::before {
    -webkit-clip-path: unset;
            clip-path: unset;
    left: 0;
    width: 100%;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico .label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 54px;
    left: unset;
    bottom: 0;
    position: absolute;
    -webkit-clip-path: unset;
            clip-path: unset;
    background-color: rgba(16, 25, 82, 0.7);
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico .label span {
    left: unset;
    display: block;
    height: auto;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    text-transform: uppercase;
    background-color: transparent;
    position: unset;
    box-sizing: border-box;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico button {
    transform: unset;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(3)::before {
    background-position: -110px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(4)::before {
    background-position: -220px -10px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico:hover .label span {
    color: #101952;
  }
}
@media (max-width: 620px) {
  #servicos .servicos-lista .col-12 .serv_prev {
    left: 10px;
  }
  #servicos .servicos-lista .col-12 .serv_next {
    right: 10px;
  }
}
@media (max-width: 460px) {
  #servicos .servicos-lista {
    height: 120px;
  }
  #servicos .servicos-lista .col-12 {
    height: 120px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs {
    height: 120px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico {
    height: 120px;
    width: 25vw;
    font-size: 0.65rem;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico .label {
    height: 42px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico .label span {
    padding: 0 5px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico button {
    box-sizing: border-box;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(3)::before {
    background-position: -90px;
  }
  #servicos .servicos-lista .col-12 .nt-tabs .servico:nth-child(4)::before {
    background-position: -160px -10px;
  }
}
@media (max-width: 320px) {
  #servicos .servicos-lista .col-12 .nt-tabs .servico .label span {
    padding: 0;
  }
}
#servicos .serv_fotos {
  position: relative;
  padding-bottom: 80px;
}
#servicos .serv_fotos .sbtprev, #servicos .serv_fotos .sbtnext {
  position: absolute;
  bottom: 40px;
  left: 50%;
  color: #101952;
  transition: color 0.5s, opacity 0.5s;
  opacity: 1;
}
#servicos .serv_fotos .sbtprev:hover, #servicos .serv_fotos .sbtnext:hover {
  color: #a9001c;
}
#servicos .serv_fotos .sbtprev[disabled], #servicos .serv_fotos .sbtnext[disabled] {
  opacity: 0.3;
}
#servicos .serv_fotos .sbtprev {
  transform: translateX(-100%);
}
#servicos .serv_fotos .more-insta {
  color: #a9001c;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}
#servicos .serv_fotos .fotogal {
  opacity: 0;
  transition: opacity 0.5s;
  display: none;
}
#servicos .serv_fotos .fotogal.selected {
  display: block;
}
#servicos .serv_fotos .fotogal.show {
  opacity: 1;
}
#servicos .serv_fotos .fotos, #servicos .serv_fotos .fotos_antdep {
  width: calc(90% - 20px);
  box-sizing: border-box;
  padding: 40px 10px;
  margin: 0 auto;
  position: relative;
}
#servicos .serv_fotos .fotos::before, #servicos .serv_fotos .fotos_antdep::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 4;
  left: -32px;
  top: 0px;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 60px solid #a9001c;
  transform: rotate(-45deg);
}
#servicos .serv_fotos .fotos::after, #servicos .serv_fotos .fotos_antdep::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 4;
  right: -32px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 60px solid #a9001c;
  transform: rotate(135deg);
}
#servicos .serv_fotos .fotos .foto, #servicos .serv_fotos .fotos_antdep .foto {
  position: relative;
  z-index: 1;
}
#servicos .serv_fotos .fotos .foto img, #servicos .serv_fotos .fotos_antdep .foto img {
  width: 100%;
}
#servicos .serv_fotos .fotos .foto:nth-of-type(2), #servicos .serv_fotos .fotos_antdep .foto:nth-of-type(2) {
  margin-top: 10px;
}
#servicos .serv_fotos .fotos .foto_ant, #servicos .serv_fotos .fotos_antdep .foto_ant {
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 10px;
}
#servicos .serv_fotos .fotos .foto_ant img, #servicos .serv_fotos .fotos_antdep .foto_ant img {
  width: 60%;
}
#servicos .serv_fotos .fotos .foto_ant::after, #servicos .serv_fotos .fotos_antdep .foto_ant::after {
  content: "antes";
  position: absolute;
  bottom: -30px;
  left: 0;
  font-size: 1rem;
  font-style: italic;
  color: #a9001c;
}
#servicos .serv_fotos .fotos .foto_prox, #servicos .serv_fotos .fotos_antdep .foto_prox {
  position: absolute;
  z-index: 2;
  bottom: 40px;
  right: 10px;
  text-align: right;
}
#servicos .serv_fotos .fotos .foto_prox img, #servicos .serv_fotos .fotos_antdep .foto_prox img {
  width: 60%;
}
#servicos .serv_fotos .fotos .foto_prox::after, #servicos .serv_fotos .fotos_antdep .foto_prox::after {
  content: "depois";
  position: absolute;
  bottom: -30px;
  right: 90px;
  font-size: 1rem;
  font-style: italic;
  color: #a9001c;
}
#servicos .serv_fotos .fotos .seta_bg_topo, #servicos .serv_fotos .fotos_antdep .seta_bg_topo {
  display: block;
  position: absolute;
  z-index: 0;
  left: -40px;
  top: 16px;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 60px solid rgba(169, 0, 28, 0.5);
  transform: rotate(-45deg);
}
#servicos .serv_fotos .fotos .seta_bg_fundo, #servicos .serv_fotos .fotos_antdep .seta_bg_fundo {
  display: block;
  position: absolute;
  z-index: 0;
  right: -40px;
  bottom: 16px;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 60px solid rgba(169, 0, 28, 0.5);
  transform: rotate(135deg);
}
@media (max-width: 767px) {
  #servicos .serv_fotos .fotos, #servicos .serv_fotos .fotos_antdep {
    margin-top: 20px;
  }
}
@media (max-width: 575px) {
  #servicos .serv_fotos .fotos_antdep {
    height: auto !important;
  }
  #servicos .serv_fotos .fotos_antdep .foto_ant {
    position: relative;
    top: -5px;
    left: 0;
    padding-bottom: 60px;
  }
  #servicos .serv_fotos .fotos_antdep .foto_ant a {
    display: block;
  }
  #servicos .serv_fotos .fotos_antdep .foto_ant img {
    width: 100%;
  }
  #servicos .serv_fotos .fotos_antdep .foto_ant::after {
    bottom: 30px;
  }
  #servicos .serv_fotos .fotos_antdep .foto_prox {
    position: relative;
    top: -5px;
    left: 0;
    padding-bottom: 60px;
  }
  #servicos .serv_fotos .fotos_antdep .foto_prox a {
    display: block;
  }
  #servicos .serv_fotos .fotos_antdep .foto_prox img {
    width: 100%;
  }
  #servicos .serv_fotos .fotos_antdep .foto_prox::after {
    bottom: 30px;
    right: unset;
    left: 0;
  }
  #servicos .serv_fotos .fotos_antdep::after {
    bottom: 56px;
  }
  #servicos .serv_fotos .fotos_antdep .seta_bg_fundo {
    bottom: 72px;
  }
}
@media (max-width: 460px) {
  #servicos .serv_fotos .more-insta {
    font-size: 0.85rem;
    line-height: 0.95rem;
  }
}
#servicos #serv_carpetes .fotogal[data-index="0"] {
  height: 440px;
}
@media (max-width: 991px) {
  #servicos #serv_carpetes .fotogal[data-index="0"] {
    height: 340px;
  }
}
@media (max-width: 767px) {
  #servicos #serv_carpetes .fotogal[data-index="0"] {
    height: 500px;
  }
}
#servicos #serv_carpetes .fotogal[data-index="1"] {
  height: 460px;
}
@media (max-width: 991px) {
  #servicos #serv_carpetes .fotogal[data-index="1"] {
    height: 350px;
  }
}
@media (max-width: 767px) {
  #servicos #serv_carpetes .fotogal[data-index="1"] {
    height: 520px;
  }
}
#servicos #serv_ec .fotogal[data-index="0"] {
  height: 460px;
}
@media (max-width: 991px) {
  #servicos #serv_ec .fotogal[data-index="0"] {
    height: 350px;
  }
}
@media (max-width: 767px) {
  #servicos #serv_ec .fotogal[data-index="0"] {
    height: 520px;
  }
}
#servicos #serv_ec .fotogal[data-index="2"] {
  height: 500px;
}
@media (max-width: 991px) {
  #servicos #serv_ec .fotogal[data-index="2"] {
    height: 380px;
  }
}
@media (max-width: 767px) {
  #servicos #serv_ec .fotogal[data-index="2"] {
    height: 560px;
  }
}
#servicos #serv_dp .fotogal[data-index="0"] {
  height: 400px;
}
@media (max-width: 991px) {
  #servicos #serv_dp .fotogal[data-index="0"] {
    height: 310px;
  }
}
@media (max-width: 767px) {
  #servicos #serv_dp .fotogal[data-index="0"] {
    height: 450px;
  }
}
#servicos .acc {
  margin-bottom: 10px;
}
#servicos .acc:last-child {
  margin-bottom: 0;
}
#servicos .acc .accordion {
  font-size: 1.08rem;
  font-weight: 700;
  transition: all 0.5s;
  cursor: pointer;
  padding-right: 40px;
  position: relative;
  color: #101952;
}
#servicos .acc .accordion:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 10px solid #101952;
  display: inline-block;
  margin-left: 5px;
  transform: rotate(0deg);
  transition: transform 0.5s, border-top 0.5s;
}
#servicos .acc .accordion.active:after {
  transform: rotate(180deg);
  border-top: 10px solid #a9001c;
}
#servicos .acc .panel {
  font-size: 16px;
  line-height: 24px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.navtabs .nt-panel {
  transition: opacity 0.15s linear;
  opacity: 0;
  display: none;
}
.navtabs .nt-panel h2 {
  font-size: 1.06rem;
  line-height: 1.04rem;
  margin-bottom: 0;
}
.navtabs .nt-panel h3 {
  font-size: 1.6rem;
  color: #a9001c;
  margin-bottom: 0.8rem;
}
.navtabs .nt-panel.active {
  display: block;
}
.navtabs .nt-panel.show {
  opacity: 1;
}

#parcerias {
  padding-top: 90px;
}
#parcerias .container {
  background-color: #FFF;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

#contato {
  padding-top: 90px;
  margin-bottom: 90px;
}
#contato .container {
  background-color: #e2e4e7;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  font-size: 1.1em;
}
#contato .container .dados .endereco {
  color: #101952;
}
#contato .container .dados .endereco * {
  color: #101952;
}
#contato .container .dados .tel {
  color: #7c7c7c;
}
#contato .container .dados .tel * {
  color: #7c7c7c;
}
#contato .container .dados .whatsapp, #contato .container .dados .instagram, #contato .container .dados .facebook, #contato .container .dados .youtube {
  color: #a9001c;
  text-transform: uppercase;
}
#contato .container .dados .whatsapp *, #contato .container .dados .instagram *, #contato .container .dados .facebook *, #contato .container .dados .youtube * {
  color: #a9001c;
}
#contato .container .dados .whatsapp i, #contato .container .dados .instagram i, #contato .container .dados .facebook i, #contato .container .dados .youtube i {
  font-size: 1.5em;
  vertical-align: middle;
}
#contato .container form button {
  background-color: #7c7c7c;
  transition: background-color 0.5s;
  padding: 5px 30px;
}
#contato .container form button:hover {
  background-color: #a9001c;
}
#contato .container form .msg {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 6px 10px;
  opacity: 1;
  transition: opacity 10s;
}
#contato .container form .msg.some {
  opacity: 0;
}
@media (max-width: 765px) {
  #contato .container form br {
    display: none;
  }
}
@media (max-width: 400px) {
  #contato .container form .row.cols-xs > * {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}
@media (max-width: 620px) {
  #contato .container form .obs-area label {
    height: auto;
  }
}
/*# sourceMappingURL=style.css.map */
